import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { mintSingleton } from 'src/client/mint';
import useOnMount from 'src/common/hooks/useOnMount';
import { selectIsNowPlayingDialogOpen } from 'src/common/selectors/dialog';

const placementName = 'FreeStarVideoAdContainer';

export default function FreeStarVideoAdContainer({
  isInNowPlayingDialog = false,
}) {
  const isNowPlayingDialogOpen = useSelector(selectIsNowPlayingDialogOpen);
  const reloadVideoAd = () => {
    mintSingleton.instance?.deleteInContentVideo?.(placementName);
    mintSingleton.instance?.requestInContentVideo?.(placementName);
  };

  useOnMount(() => {
    mintSingleton.instance?.requestInContentVideo?.(placementName);
    return () => {
      mintSingleton.instance?.deleteInContentVideo?.(placementName);
    };
  });

  useEffect(() => {
    if (!isInNowPlayingDialog) {
      return;
    }

    if (isNowPlayingDialogOpen) {
      reloadVideoAd();
    }
  }, [isInNowPlayingDialog, isNowPlayingDialogOpen]);

  return (
    <div
      id="FreeStarVideoAdContainer"
      style={{ marginTop: '10px', marginBottom: '10px' }}
    >
      <div id="freestar-video-parent">
        <div id="freestar-video-child"></div>
      </div>
    </div>
  );
}
